<template>
    <div class="pt-32 flex flex-col">
        <div class="flex flex-row">
            <div class="md:w-1/3 w-full">
                <van-cell-group class="shadow-md " inset v-if="teacher">
                    <profile-cell :user="teacher" :lang-code="'zh'" :shortBio="true" />
                </van-cell-group>
            </div>
            <div class="md:w-2/3 px-8">
                <my-month-sessions @move-page="movePage" v-if="sessionsLoaded && !showSmall" ref="calendar" />
            </div>
        </div>
        <div class="flex md:flex-row flex-col mt-8">
            <div class="md:w-1/3 w-full pb-8">
                <van-cell-group class="shadow-md" inset>
                    <van-cell size="large" is-link @click="showDate = true">
                        <template #title>
                            <span class="text-lg">{{ year }}年{{ month }}月</span>
                        </template>
                    </van-cell>
                    <van-cell title="此月预计总课时" :value="(totalMonth / 60).toFixed(2) + '小时'" />
                    <van-cell title="此月已完成课时" :value="(totalComplete / 60).toFixed(2) + '小时'" />
                    <van-cell title="此月已完成课量" :value="allSessions.length + '节'" />
                </van-cell-group>
            </div>
            <div class="md:w-2/3 w-full px-4 flex flex-wrap">
                <div v-for="(c, index) in teacherClasses" :key="index" class="md:w-1/2 w-full mb-8">
                    <van-cell-group class="shadow-md" inset>
                        <div v-if="classMap[c.classId]">
                            <van-cell :title="c.className" :value="(classMap[c.classId].total / 60).toFixed(2) + '小时'"
                                :label="'学生' + c.countStudents + '人'" is-link :to="'/classStudents/' + c.classId" />
                            <van-cell v-for="(session, index) in classMap[c.classId].sessions" :key="index" title=""
                                :label="session.title">
                                <template #value>
                                    <van-tag class="mr-4" type="primary" round plain>{{ TeacherTypes[session.tt]["zh"]
                                    }}</van-tag>
                                    <span>{{ session.du + "分钟" }}</span>
                                </template>
                            </van-cell>
                        </div>
                    </van-cell-group>
                </div>
            </div>
        </div>
    </div>
    <van-popup v-model:show="showDate" position="bottom" round>
        <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate"
            :formatter="datePickerFormat" @confirm="confirmDate" @cancel="showDate = false" />
    </van-popup>
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    CellGroup,
    Cell,
    DatetimePicker,
    Popup,
    Tag
} from "vant";
import StaticDays from "@/static_values/StaticDays.js";
import TeacherTypes from "@/static_values/StaticTeacherTypes.js";
import Helpers from "@/logics/Helpers.js";
import GetDays from "@/logics/GetDaysOfWeekInMonth.js";
import ProfileCell from "@/components/ProfileCell.vue";
import MyMonthSessions from "@/components/MyMonthSessionCalendar.vue";
import GetSession from "@/asyncs/GetSession.js";
import ViewUser from "@/asyncs/ViewUser.js";
import ListClass from "@/asyncs/ListClassBasics.js";
import LoadSession from "@/asyncs/LoadClassSession.js";

export default {
    components: {
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [DatetimePicker.name]: DatetimePicker,
        [Popup.name]: Popup,
        [Tag.name]: Tag,
        ProfileCell,
        MyMonthSessions
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "教师管理");
        const route = useRoute();
        const router = useRouter();
        const teacherId = route.params.teacherId;
        const today = new Date();
        const year = ref(today.getFullYear());
        const month = ref(today.getMonth() + 1);
        const currentDate = ref(today);
        const showDate = ref(false);
        const teacher = ref(null);
        const totalMonth = ref(0);
        const totalComplete = ref(0);
        const teacherClasses = ref([]);
        const classMap = ref({});
        const calendar = ref(null);
        const sessionsLoaded = ref(false);
        const allSessions = ref([]);

        const loadAndComputeClass = async function () {
            const listClass = await ListClass(store.state.school.code, teacherId, true);
            if (!listClass) {
                return;
            }
            teacherClasses.value = Helpers.removeClassNotInMonth(listClass, year.value, month.value);
            classMap.value = {};

            let i = 0;
            while (i < teacherClasses.value.length) {
                const c = teacherClasses.value[i];
                const classItem = {};
                const sessionList = await LoadSession(c.classId, year.value, month.value, true, teacherId);
                classItem.sessions = sessionList;

                GetDays(c.classTimes, year.value, month.value, function (day, slot) {
                    totalMonth.value += slot.duration;
                });

                let totalDuration = 0;
                sessionList.forEach(function (session) {
                    totalDuration += session.du;
                    session.title = Helpers.makeDateText(session.y, session.m, session.d) + " " + StaticDays[session.wd]["zh"] + " " + Helpers.makeClassTimeToText(session.t);
                    session.className = c.className;
                    allSessions.value.push(session);
                });
                classItem.total = totalDuration;
                totalComplete.value += totalDuration;
                classMap.value[c.classId] = classItem;
                i += 1;
                c.countStudents = 0;
                if (classItem.sessions.length) {
                    c.countStudents = Object.keys(classItem.sessions[0].check).length;
                }
            }
            sessionsLoaded.value = true;
        };

        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                teacher.value = await ViewUser(teacherId);
                if (!teacher.value) {
                    return;
                }
                await loadAndComputeClass();
                window.addEventListener("resize", onWindowSize);
                onWindowSize();
            });
        });
        const showSmall = ref(true);
        const onWindowSize = function () {
            if (window.innerWidth <= 768) {
                showSmall.value = true;
            } else {
                showSmall.value = false;
            }
        };

        const movePage = async function (page) {
            if (!page) {
                calendar.value.makeData(allSessions.value);
                return;
            }

            if (year.value === page.year && month.value === page.month) {
                if (calendar.value) {
                    calendar.value.makeData(allSessions.value);
                }
                return;
            }

            year.value = page.year;
            month.value = page.month;
            totalMonth.value = 0;
            totalComplete.value = 0;

            allSessions.value = [];
            await loadAndComputeClass();
            calendar.value.makeData(allSessions.value);
        };

        const datePickerFormat = function (type, val) {
            if (type === "year") {
                return val + "年";
            }
            if (type === "month") {
                return val + "月";
            }
            return val;
        };

        const confirmDate = function (date) {
            showDate.value = false;
            if (calendar.value) {
                calendar.value.moveToMonth(date.getMonth() + 1, date.getFullYear());
            } else {
                year.value = date.getFullYear();
                month.value = date.getMonth() + 1;
                totalMonth.value = 0;
                totalComplete.value = 0;
                allSessions.value = [];
                loadAndComputeClass();
            }
        };

        return {
            teacher,
            year,
            month,
            totalMonth,
            totalComplete,
            teacherClasses,
            classMap,
            currentDate,
            minDate: new Date(2018, 0, 1),
            maxDate: new Date(2030, 0, 1),
            datePickerFormat,
            showDate,
            confirmDate,
            allSessions,
            movePage,
            showSmall,
            calendar,
            sessionsLoaded,
            TeacherTypes,
            teacherId
        };
    }
};
</script>
