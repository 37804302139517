export default {
    1: {
        zh: "主讲老师",
        en: "Main Teacher"
    },
    2: {
        zh: "助教老师",
        en: "Teaching Assistant"
    },
    3: {
        zh: "代课老师",
        en: "Substitute Teacher"
    }
}