<template>
<v-calendar class="rounded-md w-full shadow-md" :masks="masks" :attributes="attributes" disable-page-swipe :firstDayOfWeek="1" @update:to-page="toMonth" :locale="langCode" ref="calendar">
    <template v-slot:day-content="{ day, attributes }">
        <div class="my-cell">
            <span class="text-xs ml-1 text-gray-900">{{ day.day }}</span>
            <div class="my-event-area px-1">
                <div v-for="attr in attributes" :key="attr.key" class="rounded text-xs p-1 text-white mb-2 flex flex-col cursor-pointer bg-blue-500">
                    <div class="pb-2 text-center font-bold">{{ attr.customData.className }}</div>
                    <div>{{ attr.customData.time }}</div>
                    <div>{{ attr.customData.duration }}分钟</div>
                </div>
            </div>
        </div>
    </template>
</v-calendar>
</template>

<script>
import {
    ref,
    onMounted
} from "vue";

export default {
    props: {
        langCode: {
            type: String,
            default: "zh"
        }
    },
    setup(props, {
        emit
    }) {
        const masks = {
            weekdays: "WWW"
        };
        const calendar = ref(null);
        const attributes = ref([]);

        const toMonth = function (page) {
            emit("movePage", page);
        };

        onMounted(function () {
            emit("movePage");
        });

        const makeData = function (sessions) {
            attributes.value = [];
            sessions.forEach(function (item) {
                attributes.value.push({
                    customData: {
                        className: item.className,
                        time: item.title.split(" ")[2],
                        duration: item.du,
                        order: item.d * 10000 + item.t
                    },
                    dates: new Date(item.y, item.m - 1, item.d)
                });
            });
            attributes.value.sort(function (a, b) {
                return a.customData.order - b.customData.order;
            });
        };

        const moveToMonth = function (month, year) {
            calendar.value.move({
                month,
                year
            });
        };

        return {
            calendar,
            masks,
            attributes,
            toMonth,
            moveToMonth,
            makeData
        };
    }
}
</script>

<style scoped>
.my-event-area {
    overflow-y: scroll;
    height: 75px;
    scrollbar-width: none;
}

.my-cell {
    text-align: left;
    border: 1px solid #eee;
    margin: 0px 0px -1px -1px;
    min-height: 100%;
    overflow: hidden;
}

.my-event-area::-webkit-scrollbar {
    width: 0px;
}

.my-event-area::-webkit-scrollbar-track {
    display: none;
}
</style>
